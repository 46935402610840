<template>
  <div class="modal_image">
    <r-image
      ratio="kano"
      rounded="kurza"
      :is-loading="false"
      :is-fill="false"
      :url="items[0].url"
    />
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number
    }
  },
  mounted() {
    window.onpopstate = function (events) {
      const event = new Event('click');
      const dropdownEl = document.querySelector('.rir-modal__content-rir-icon_close');
      if (dropdownEl) {
        dropdownEl.addEventListener('click', () => function (es) {
        });
        dropdownEl.dispatchEvent(event);
      }
    };
  }
};
</script>
<style scoped>
.modal_image {
  height: 100%;
  display: grid;
  align-items: center;
  padding: 0 40px;
}
img{
  max-height: 1000px;
  width: 100%;
  max-width: 1000px;
  height: auto;
}
</style>
