import ApiService from './ApiService';

export default class Api extends ApiService {
  // - справочник типов клиник, ФИО для дропдауна, координаты для карты
  async getTypes(payload) {
    const { data } = await this._axios.post('ajax.php?action=getDoctors', {
      readOnly: 1
    });
    return data;
  }

  async getUserInfo() {
    const { data } = await this._axios.get('/nginxApi.php?get=user');
    return data;
  }

  async getUser() {
    const { data } = await this._axios.get('/auth/rest/user');

    return data;
  }

  async getFavorite() {
    const { data } = await this._axios.post('/ajax.php?action=operateFavorite', {
      currentMainMenuNumber: 38
    });

    return data;
  }

  async getFavoriteClick(id) {
    const { data } = await this._axios.post('/ajax.php?action=operateFavorite', {
      id,
      currentMainMenuNumber: 38
    });

    return data;
  }

  async getActive(paylod) {
    let page = '';
    if (paylod.page) {
      page = `&page=${paylod.page}&pageSize=50`;
    }
    const { data } = await this._axios.post(`/ajax.php?action=getRegions${page}`, { withHidden: 1, currentMainMenuNumber: 38 });

    return data;
  }

  async getActivity(payload) {
    const { data } = await this._axios.put('/ajax.php?action=getRegions', payload);

    return data;
  }

  async setSaveNew(item) {
    const { data } = await this._axios.post('/ajax.php?action=operateRegion', {
      id: -1,
      action: 'update',
      currentMainMenuNumber: 38,
      item
    });
  }

  async setTransport(item) {
    const { data } = await this._axios.post(`/transportProxy.php?url=stations&lat=${item.lat}&lng=${item.lng}&radius=600`);

    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('/mob_ajax.php?action=geosearch', payload);
    return data;
  }
}
