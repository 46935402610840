<template>
  <div class="modal_image">
    <img
      :src="img"
      :alt="name"
    >
  </div>
</template>

<script>
export default {
  name: 'ModalImage',
  props: {
    img: {
      type: String
    },
    name: {
      type: String
    }
  }
};
</script>

<style scoped>
.modal_image{
  padding: 0 40px;
}
img{
  max-width: 100%;
  max-height: 100%;
}
</style>
