<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div @click="upload = !upload">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
            fill="rocky"
          />
        </div>
        <div class="parent align-center">
          <div class="container-upload">
            <h1 class="ricotta mb-6">
              Добавить помещения для сдачи в аренду
            </h1>
            <div class="mb-4 font">
              <r-input
                label="Наименование"
                :error="!itemsDef.title"
                v-model="itemsDef.title"
              />
              <r-select
                class="mt-4 mb-4 flex-1 delete-warn__select"
                :items="objectTypes"
                label="Тип объекта"
                :value="itemsDef.type"
                v-model="itemsDef.type"
              />
              <r-input
                class="mt-6 flex-1"
                label="Собственник"
                v-model="itemsDef.owner"
              />
              <r-input
                :value="null"
                class=" mt-4"
                label="Адрес"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
                v-model="itemsDef.address"
                @blur="searchAddress(itemsDef.address)"
                @onPressEnter="searchAddress(itemsDef.address)"
              >
                <template #after>
                  <r-icon
                    icon="search"
                    fill="metropolis"
                  />
                </template>
              </r-input>
              <div class="culture__map-cont mt-4">
                <div class="culture__map">
                  <rir-map
                    :search="false"
                    v-if="upload"
                    :control-color="false"
                    :center="coordinates"
                  >
                    <div>
                      <ymap-marker
                        :key="1"
                        :coords="coordinates"
                        :marker-id="1"
                        :options="{ hideIconOnBalloonOpen: false,
                                    draggable: true }"
                        :icon="$markerIcon()"
                        @dragend="onDragMarker"
                        cluster-name="main"
                      />
                    </div>
                  </rir-map>
                </div>
              </div>
            </div>
            <div class="flex mb-4">
              <div class="video__login">
                <r-input
                  class=" mr-2 flex-1"
                  label="Площаль м²"
                  v-model="itemsDef.areaS"
                />
              </div>
              <div class="video__pass">
                <r-input
                  class="ml-2 flex-1"
                  label="Этаж"
                  v-model="itemsDef.floorS"
                />
              </div>
            </div>
            <r-textarea
              class="mb-6"
              value=""
              label="Состояние и описание"
              :rows="6"
              :max-rows="6"
              v-model="itemsDef.description"
            />
            <uploadFile
              :multiple="true"
              :count-file="0"
              :accept-file="[&quot;.jpg&quot;,&quot;.jpeg&quot;,&quot;.png&quot;,&quot;.gif&quot;]"
              upload-url="/cityProperty/rest/service/company/img"
              :value="[]"
              :params-body="{}"
              @input="onChangeFiles"
              class="mb-6"
            />
            <div
              :key="image"
              v-show="itemsDef.img != ''"
              style="display: table;width: 100%;"
              class="mb-6"
            >
              <img
                :src="'/cityProperty/rest/' + itemsDef.img"
                class="imgSize"
                style="height: 56px; float: left;margin-right: 12px;"
              >
              <div class="clear" />
              <div class="context">
                {{ items['nameImg'] }}
              </div>
              <!-- <span class="spanKb" >{{ items['contentLen']}} Kb</span> -->
              <div
                v-if="itemsDef.img != ''"
                style="float: right;margin-top: -20px;cursor: pointer;"
                class="float-left mr-5 block pointer eye"
                @click="deletePhoto"
              >
                <r-icon
                  icon="delete"
                  style="opacity: 1;"
                  class="block"
                  fill="fargo"
                />
              </div>
            </div>
            <div class="flex mb-4 flex-video">
              <div class="video__login-phone">
                <r-input
                  class="mt-4 flex-1 flex_input"
                  label="Телефон"
                  v-model="itemsDef.phone"
                  v-mask="'+#(###)###-####'"
                  @keyup="validPhone"
                />
                <div class="mt-4 buttonPhone">
                  <r-button-simple
                    class="buttonPhone__cont"
                    size="larishae"
                    type="primary"
                    color="rocky"
                    :disabled="phone"
                    @click="addPhone"
                    icon="add"
                  />
                </div>
                <div class="buttonPhoneContent">
                  <div
                    class="mt-4 buttonPhoneContent__icon"
                    v-for="(item, index) in objectsPhone"
                  >
                    <div style="display: flex;">
                      <r-icon
                        class="mr-2 mt-4"
                        fill="rocky"
                        style="opacity: 1;"
                        icon="telephone"
                        size="16"
                      />
                      <div style="margin-top: 16px;opacity: 0.72;">
                        {{ item }}
                      </div>
                    </div>
                    <div
                      class="buttonPhoneContent__delete"
                      @click="deletePhone(index)"
                    >
                      <r-icon
                        class="mr-3"
                        style="opacity: 1;"
                        icon="delete"
                        fill="fargo"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="video__pass-email">
                <r-input
                  class="mt-4 flex-1 emailValid"
                  label="Имейл"
                  v-model="itemsDef.email"
                  @keyup="validEmail"
                />
                <div class="mt-4 emailAdd">
                  <r-button-simple
                    class="emailAdd__cont"
                    size="larishae"
                    type="primary"
                    color="rocky"
                    :disabled="email"
                    @click="addEmail"
                    icon="add"
                  />
                </div>
                <div>
                  <div class="objectEmail">
                    <div
                      class="mb-4 objectEmail__table"
                      v-for="(item, index) in objectsEmail"
                    >
                      <div style="display: flex;">
                        <p style="margin-top: 16px;opacity: 0.72;">
                          {{ item }}
                        </p>
                      </div>
                      <div
                        class="objectEmail__icon"
                        @click="deleteEmail(index)"
                      >
                        <r-icon
                          class="mr-3"
                          icon="delete"
                          fill="fargo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex mt-2 mb-4"
              style="width: 100%;display: inline-block;"
            >
              <r-button
                class="flex-1"
                @click="Save()"
                :disabled="!itemsDef.title || !itemsDef.address || !itemsDef.areaS"
                title="Отправить на модерацию"
                width="wide"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="uploadSuccess"
      class="upload"
    >
      <div>
        <div @click="uploadSuccess = !uploadSuccess">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
            fill="rocky"
          />
        </div>
        <div class="parent align-center">
          <div
            class="container-upload"
            style="margin-top: calc(50% - 50px)"
          >
            <r-icon
              icon="selected"
              size="56"
              fill="matrix"
            />
            <h1 class="ricotta mb-6">
              Отправлено на модерацию
            </h1>
            <div class="textSucces mb-8">
              В ближайшее время мы ознакомимся с информацией о вашем помещении
              под сдачу в аренду и разместим его на сайте.
            </div>
            <div
              class="flex mt-2 mb-4"
              style="width: 100%;display: inline-block;"
            >
              <r-button
                class="flex-1"
                style="background-color: #E4EDFB; color: #214EB0;"
                @click="uploadSuccess = !uploadSuccess"
                title="Отлично"
                width="wide"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import axios from 'axios';
import VueMask from 'v-mask';
import RirMap from './RirMap.vue';
import uploadFile from '@/components/uploadFile';
import PlaceApi from '../api/Api';
import rir from '../plugins/RirLib';

Vue.use(VueMask);
Vue.use(vClickOutside);

export default {
  name: 'Upload',
  components: {
    RirMap,
    uploadFile
  },
  data() {
    return {
      image: 0,
      time: false,
      upload: false,
      uploadEnd: false,
      email: true,
      phone: true,
      countEmail: 0,
      countPhone: 0,
      objectsEmail: [],
      objectsPhone: [],
      coordinates: this.$cityCenter,
      items: [],
      uploadSuccess: false,
      objectTypes: [
        { id: '0', title: '' },
        { id: '1', title: 'Муниципальные' },
        { id: '2', title: 'Коммерческие' }
      ],
      itemsDef: {
        address: null,
        title: null,
        owner: null,
        areaS: null,
        floorS: null,
        description: null,
        phone: null,
        email: null,
        img: '',
        images: []
      }
    };
  },
  created() {
    this.itemsDef.img = '';
  },
  methods: {
    deletePhone(index) {
      this.objectsPhone.splice(index, 1);
    },
    deleteEmail(index) {
      this.objectsEmail.splice(index, 1);
    },
    addPhone() {
      this.objectsPhone = [this.itemsDef.phone, ...this.objectsPhone];
      console.log(this.objectsPhone);
      this.itemsDef.phone = '';
      this.phone = true;
    },
    addEmail() {
      this.objectsEmail = [this.itemsDef.email, ...this.objectsEmail];
      this.itemsDef.email = '';
      this.email = true;
    },
    validEmail() {
      const re = /\S+@\S+\.\S+/;
      if (re.test(this.itemsDef.email)) {
        this.email = false;
      } else {
        this.email = true;
      }
    },
    validPhone() {
      const re = /\+\d\(\d{3}\)\d{3}-\d{4}/;
      if (re.test(this.itemsDef.phone)) {
        this.phone = false;
      } else {
        this.phone = true;
      }
    },
    deletePhoto() {
      this.itemsDef.img = '';
      this.image++;
    },
    onChangeFiles(files) {
      if (!files?.length) {
        this.itemsDef.images = [];
        return;
      }
      if (files[0]?.data?.error) {
        rir.framework.notification.send({
          title: files[0]?.data?.error || 'Ошибка!',
          seconds: 5,
          hiddenClose: false,
          iconOptions: {
            icon: 'warning',
            fill: 'fargo'
          }
        });

        return;
      }
      if (files[0]?.error) {
        rir.framework.notification.send({
          title: files[0]?.error || 'Ошибка!',
          seconds: 5,
          hiddenClose: false,
          iconOptions: {
            icon: 'warning',
            fill: 'fargo'
          }
        });

        return;
      }

      this.itemsDef.images = files;
      this.itemsDef.img = files[0].id;
      this.items.nameImg = files[0].name;
      // this.items['contentLen'] = (formattedFiles[0].contentLength/ 1000).toFixed(2);
      // console.log(this.items['contentLen']);
      this.image++;
    },
    uploadContainer() {
      this.upload = true;
    },
    onDragMarker(e) {
      /*
      this.coordinates = e.get('target').geometry._bounds[0];
      this.searchAddress(this.coordinates); */
      const coords = e.originalEvent.target.geometry.getCoordinates();
      if (coords?.length === 2) {
        this.coordinates = coords;
        this.searchAddress(this.coordinates);
      }
    },
    async searchAddress(address) {
      if (!address) return;
      const api = new PlaceApi();

      const data = {};
      const isFindAddress = Array.isArray(address);
      console.log('isFindAddress', address);
      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.itemsDef.address;
      }

      const res = await api.getAddress(JSON.stringify(data));

      if (isFindAddress && res?.address) {
        if (res?.addressFull?.street && res?.addressFull?.house) {
          this.itemsDef.address = `${res.addressFull.street} ${res.addressFull.house}`;
        } else {
          this.itemsDef.address = res.address;
        }
      } else if (!isFindAddress && res?.lat && res?.lng) {
        this.lat = res.lat;
        this.lng = res.lng;
        console.log('latlng', this.lat, this.lng);
        this.coordinates = [this.lat, this.lng];
      }
      // this.isChange('address')
    },
    Save() {
      let images = [];
      const v = this;
      v.itemsDef.images.map(el => (function () {
        images.push('/cityProperty/rest' + el.id);
      }));
      const json = {
        address: v.itemsDef.address,
        areaS: v.itemsDef.areaS,
        description: v.itemsDef.description,
        email: JSON.stringify(v.objectsEmail),
        firm: v.itemsDef.owner,
        floorS: v.itemsDef.floorS,
        lat: v.coordinates[0] ? v.coordinates[0] : 47.5259125,
        lng: v.coordinates[1] ? v.coordinates[1] : 42.1899268,
        phone: JSON.stringify(v.objectsPhone),
        photo1: v.itemsDef.img ? '/cityProperty/rest' + v.itemsDef.img : '',
        title: v.itemsDef.title,
        type: v.itemsDef.type,
        images: images,
        inApproval: 1
      };
      if (json) {
        v.$store.dispatch('loadSaveNew', json).then(result => {
          this.upload = false;
          this.uploadSuccess = true;
          v.itemsDef = {
            address: null,
            title: null,
            owner: null,
            areaS: null,
            floorS: null,
            description: null,
            phone: null,
            email: null,
            images: null,
            img: ''
          };
          v.objectsEmail = [];
          v.objectsPhone = [];
        }, error => {
          console.error(error);
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.float-left {
  z-index: 2;
  position: relative;
}
.video {

  &__login {
    width: 50%;
  }
  &__login-phone {
    width: calc(50% - 12px);
  }
  &__pass {
    width: 50%;
    float: right;
  }
  &__pass-email {
    width: calc(50% - 12px);
    margin-left: 24px;
  }
}
@media screen and (max-width: 1200px) {
  .flex-video {
    display: table;
    width: 100%;
  }
  .video {
    &__login-phone {
      width: 100%;
    }
    &__pass-email {
      width: 100%;
      margin-left: 0px;
    }
  }
}
.align-center {
  align-items: center;
  float: left;
  margin-top: 5px;
}
.align-centerEnd {
  align-items: center;
  float: left;
  margin-top: 5px;

}

.upload {
  background-color: white;
  z-index: 49;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}
.upload::-webkit-scrollbar-track {
  width: 4px;
  background: #FFFFFF;
}

.upload::-webkit-scrollbar {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.upload::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 20%;
  margin-left: 31%;
  margin-top: -150px;
  @media(max-width: 599px){
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    padding: 0 2.5vw;
  }
}

.container-upload {
  width: 87%;
  height: 300px;
  @media(max-width: 599px){
    width: 100%
  }
}

.parentEnd {
  display: block;
  position: absolute;
  width: 70%;
  height: 400px;
  top: 50%;
  margin-left:28%;
  margin-top: -150px;
}

.container-uploadEnd {
  width: 55%;
  height: 300px;
}

.margin-right {
  float: right;
  margin-top: 12px;
  margin-right: 12px;
  z-index: 1000;
  cursor: pointer;
}

.font {
  font-family: Golos UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}
.culture {
  max-width: 100%;
  flex-direction: column-reverse;

  &__cards {
    width: 100%;
  }

  &__map-cont {
    position: relative;
    top: unset;
    background-color: #fff;
    height: unset;
    padding: 0;
    margin-left: unset;
    width: 100%;
  }

  &__map {
    height: 160px;
    margin-bottom: 32px;
  }

  &__map--collapsed {
    height: 112px;
  }

  &__map-collapse-btn {
    height: 40px;
    width: 174px;
    border-radius: 8px;
    padding: 10px 16px;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 87px);
    background-color: var(--rir-arrival);
    cursor: pointer;
    display: block;

    :first-child {
      color: var(--rir-godfather);
      border-bottom: 2px dashed #214eb052;
    }
  }

}

.hours {
  display: table;
  background: #FFFFFF;
  box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  border-radius: 8px;
  margin-top: 60px;
  z-index: 1200;
  position: relative;
  width: 100%;
  font-family: Golos UI;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  text-align: center;
  height: 160px;
  position: absolute;
  width: 300px;
  margin-top: 0px;

  .hour {
    margin-top: 5px;
    max-height: 150px;
  }

  .minutes {
    margin-top: 5px;
    max-height: 150px;
  }

  .hour::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  .hour::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  .hour::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  .minutes::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  .minutes::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.40;
  }

  .minutes::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.40;
  }

  .time-hover {
    width: 60px;
    margin: auto;
    margin-top: 6px;
  }

  .time-hover:hover {
    width: 60px;
    background: #e4edfb;
    margin: auto;
    color: #3D75E4;
    border-radius: 5px;
    margin-top: 6px;
  }
}
.buttonPhone{
  display: table;
  float: right;
  &__cont {
    width: 40px;
  }
}
.emailValid {
  width: calc(100% - 50px);
  float: left;
}

.emailAdd {
  display: table;
  float: right;
  &__cont {
    width: 40px;
  }
}
.objectEmail {
  display: table;
  width: 100%;
  &__table {
    display: table;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #04153E;
  }
  &__icon {
    display:table;
    float: right;
    cursor: pointer;
    margin-top: -16px;
  }
}
.buttonPhoneContent {

  &__icon {
    display: table;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #04153E;
  }

  &__delete {
    display:table;
    float: right;
    cursor: pointer;
    margin-top: -16px;
  }
}
.buttonPhone{
  display: table;
  float: right;
  &__cont {
    width: 40px;
  }
}
.flex_input{
  width: calc(100% - 50px);
  float: left;
}
.clear {
  height: 56px;
  background: #3D75E4;
  opacity: 0.08;
  border-radius: 8px;
}
.context {
  height: 30px;
  max-width: 200px;
  margin-top: -55px;
  overflow: hidden;
  line-height: 28px;
  margin-bottom: 10px;
}
.spanKb {
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #3D75E4;
}
.textSucces {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #04153E;
  opacity: 0.72;
}
</style>
