import Vue from 'vue';
import VueRouter from 'vue-router';
import Rent from '../views/Rent.vue';
import ObjectInfo from '../views/ObjectInfo.vue';
import ObjectList from '../views/ObjectList.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Rent',
    component: Rent,
    children: [
      {
        path: '',
        name: 'list',
        component: ObjectList
      },
      {
        path: 'info/:id',
        name: 'info',
        component: ObjectInfo
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

export default router;
