<template>
  <div class="stops-list">
    <div
      class="stops-list__stop"
      v-for="(item, index) in list"
      :key="index"
    >
      <p class="mb-1 feta">
        {{ item.name }}
      </p>
      <transport-tag
        class="mr-2"
        v-for="(route, index) in item.routes"
        :key="index"
        :type="route.vehicleTypes[0]"
        :route="index"
        v-bind="route"
      />
    </div>
  </div>
</template>

<script>
import TransportTag from './TransportTag.vue';

export default {
  name: 'StopsList',
  components: {
    TransportTag
  },
  props: ['list']
};
</script>

<style lang="scss" scoped>
.stops-list {
  &__stop {
    margin-top: 12px;
    &:first-child {
      margin-top: 8px;
    }
  }
}
</style>
