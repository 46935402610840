<template>
  <!-- <section class="rir-gallery" :style="style"> -->
  <section class="rir-gallery">
    <div
      :style="{ ...imageView }"
      class="rir-gallery__preview"
    >
      <div class="rir-gallery__actions">
        <a @click="changeImg('PREV')">
          <r-icon
            style="justify-self: flex-start"
            size="32"
            fill="amelie"
            icon="arrow-left"
          />
        </a>
        <loading-ring
          color="var(--rir-rocky)"
          v-if="isLoading"
        />
        <a
          v-else
          @click="viewImg"
        >
          <r-icon
            v-if="showZoom"
            class="rir-gallery__detail"
            size="56"
            fill="amelie"
            icon="search"
          />
        </a>
        <a @click="changeImg('NEXT')">
          <r-icon
            style="justify-self: flex-end"
            size="32"
            fill="amelie"
            icon="arrow-right"
          />
        </a>
      </div>
      <div class="rir-gallery__count">
        {{ indexImage + 1 }} из {{ images.length }}
      </div>
      <p
        v-if="showTitle"
        class="rir-gallery__title parmigiano"
      >
        {{ images[indexImage].filename }}
      </p>
    </div>
    <r-modal
      ref="modal"
      close-icon
    />
  </section>
</template>

<script>
import ModalGallery from '@/components/ModalGallery';

export default {
  name: 'RirGallery',
  components: {
    ModalGallery
  },
  props: {
    // ratio: {
    //   type: Number,
    //   default: 1.7
    // },
    files: {
      type: Array,
      default: () => []
    },
    showZoom: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    openModal: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number
    }
  },
  data: () => ({
    indexImage: 0,
    images: []
  }),
  computed: {
    // style() {
    //   return {
    //     paddingBottom: `${100 / this.ratio}%`
    //   };
    // },
    imageView() {
      return {
        backgroundImage: `url(${this.images[this.indexImage].blob})`
      };
    },
    isLoading() {
      return this.images[this.indexImage].isLoading;
    }
  },
  created() {
    this.images = this.files.map(f => ({
      ...f,
      sizeFile: null,
      blob: null,
      isLoading: true
    }));
  },
  mounted() {
    this.getDataBlob();
    this.indexImage = this.index || 0;
  },
  methods: {
    async getDataBlob() {
      try {
        for (const img of this.images) {
          const response = await fetch(img.url);
          if (response.status === 404) {
            throw Error('error load image - 404');
          }
          const blob = await response.blob();
          img.sizeFile = blob.size;
          img.blob = URL.createObjectURL(blob);
          img.isLoading = false;
        }
      } catch (err) {
        this.fileUrl = 'block';
      }
    },
    changeImg(type) {
      switch (type) {
        case 'PREV':
          if (this.indexImage === 0) {
            this.indexImage = this.images.length - 1;
          } else {
            this.indexImage--;
          }
          break;
        case 'NEXT':
          if (this.indexImage === this.images.length - 1) {
            this.indexImage = 0;
          } else {
            this.indexImage++;
          }
      }
    },
    async viewImg() {
      console.log(this.indexImage);
      const index = this.indexImage;
      const img = this.images.map(response => ({
        filename: '',
        url: response.url
      }));

      await this.$refs.modal.openModal(ModalGallery, {
        items: img,
        index
      });
    }
  }
};
</script>
