<template>
  <div>
    <tag-carousel
      :items="objectTypes"
      @select="onChangeType"
    />

    <loader v-if="isLoading" />
    <div
      v-else
      class="medicine mt-8"
    >
      <div class="medicine__cards">
        <router-link
          v-for="(item, index) in filteredObjects"
          :key="item.id"
          :to="{
            name: 'info',
            params: { id: item.id }
          }"
        >
          <object-card
            :name="item.title"
            :address="item.address"
            :img="item.photo1"
            :selected-category="selectedCategory"
          >
            <div
              class="flex"
              v-if="item.phone"
            >
              <r-icon
                class="mr-2"
                icon="telephone"
                fill="rocky"
              />
              <span
                class="briscola mr-4 text-rocky"
                v-for="(phone, index) in item.phone"
                :key="index"
              >{{ phone }}</span>
            </div>
          </object-card>
        </router-link>
      </div>
      <div class="medicine__map-cont">
        <div
          :class="['medicine__map', { 'medicine__map--collapsed': isMapCollapsed }]"
          ref="map"
        >
          <rir-map
            v-if="showMap"
            v-model="search"
            search
          >
            <div :key="keyCount">
              <ymap-marker
                v-for="marker in filteredObjects"
                :key="`institution_${marker.id}`"
                :coords="[`${marker.lan || 0}`, `${marker.lng || 0}`]"
                :marker-id="marker.id"
                :icon="$markerIcon()"
                :balloon="{header: {id: marker.id, name: marker.title}}"
                :options="{ hideIconOnBalloonOpen: false }"
                cluster-name="main"
                @click="onMarkerClick"
                @balloonopen="onBalloonOpen()"
                @balloonclose="onBalloonClose()"
              >
                <balloon-card
                  slot="balloon"
                  :id="marker.id"
                  :title="marker.title"
                  :address="marker.address"
                  :img="marker.photo1 ? marker.photo1 : null"
                >
                  <a
                    :href="'/create-route?toCoords='+ `${marker.lan || 0},${marker.lng || 0}`"
                    class="flex"
                  >
                    <div
                      class="flex mt-3"
                      style="cursor: pointer;"
                    >
                      <r-icon
                        class="mr-2"
                        icon="geopoint"
                        size="16"
                        fill="rocky"
                      />
                      <span class="briscola rocky--text">Построить маршрут</span>
                    </div>
                  </a>
                </balloon-card>
              </ymap-marker>
            </div>
          </rir-map>
          <button
            class="medicine__map-collapse-btn feta"
            @click="isMapCollapsed = !isMapCollapsed"
          >
            <span> {{ isMapCollapsed ? 'Развернуть' : 'Свернуть' }} карту </span>
          </button>
        </div>
        <div class="add">
          <div class="add__text">
            <div class="add__title">
              Сдаете свое помещение?
            </div>
            <div
              class="add__bottom flex align-items-center"
              @click="AddClick"
            >
              Разместить объявление
              <r-icon
                icon="arrow-bold-right"
                size="8"
                class="ml-2"
                fill="gentlemen"
              />
            </div>
          </div>
          <img
            class="add__image"
            src="../assets/house.svg"
            alt=""
          >
        </div>
      </div>
    </div>
    <upload
      ref="upload"
      @active="activateds"
    />
  </div>
</template>

<script>
import ObjectCard from '@/components/ObjectCard.vue';
import TagCarousel from '../components/TagCarousel.vue';
import RirMap from '../components/RirMap.vue';
import BalloonCard from '../components/BalloonCard.vue';
import Upload from '../components/Upload.vue';
import Loader from '@/components/Loader.vue';

export default {
  name: 'RentList',
  components: {
    Loader,
    ObjectCard,
    TagCarousel,
    RirMap,
    BalloonCard,
    Upload
  },

  data() {
    return {
      keyCount: 0,
      listAdd: 0,
      currentMarkerId: null,
      search: null,
      objectTypes: [
        {
          id: 0,
          title: 'Все',
          active: true
        },
        {
          id: 1,
          title: 'Муниципальные',
          active: false
        },
        {
          id: 2,
          title: 'Коммерческие',
          active: false
        }
      ],
      selectedCategory: 0,
      isMapCollapsed: false,
      showMap: false
    };
  },

  computed: {
    allObjects() {
      const _this = this;

      if (!_this.$store.state?.active?.all?.length) return [];
      const objects = _this.$store.state.active.all;

      return objects
        .map(el => {
          if (el?.id) {
            return {
              id: el.id,
              address: el.address ? el.address : '-',
              floorS: el.floorS,
              title: el.title ? el.title : '',
              lan: el.lat ? el.lat : 47.521196,
              lng: el.lng ? el.lng : 42.180914,
              coordinates: [el.lat, el.lng],
              inApproval: el.inApproval,
              phone: el.phone ? (JSON.parse(el.phone).length > 0 ? JSON.parse(el.phone) : '') : '',
              type: el.type,
              photo1: el.photo1 ? el.photo1 : null,
              hidden: el.hidden
            };
          }
        })
        .sort((a, b) => (b.count < 20) && a.address.localeCompare(b.address));
    },
    // Фильтрация списка домов
    filteredObjects() {
      const _this = this;
      let list = [];
      if (_this.listAdd == 0) {
        _this.listAdd = _this.allObjects;
      }
      list = _this.listAdd;
      list = list.filter(el => ((el.inApproval == 0)));
      list = list.filter(el => ((el.hidden == 0)));
      if (this.selectedCategory == 1) {
        list = list.filter(el => ((el.type == 1)));
      }
      if (this.selectedCategory == 2) {
        list = list.filter(el => ((el.type == 2)));
      }
      if (this.search) {
        list = list.filter(el => (el?.address
          ? el?.address.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
          : false));
      }
      _this.keyCount++;
      return list;
    },
    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },

  async mounted() {
    this.$store.dispatch('loadActive', { page: false });
  },

  activated() {
    this.showMap = true;
  },

  deactivated() {
    this.showMap = false;
  },
  beforeDestroy() {
    this.$refs.map.removeEventListener('click', e => this.routeToInfo(e));
  },

  methods: {
    activateds() {
    },
    AddClick() {
      this.$refs.upload.uploadContainer();
    },
    routeToInfo(e) {
    },
    onMarkerClick(e) {
      this.currentMarkerId = e.originalEvent.target.properties._data.markerId;
    },
    onBalloonOpen() {
      this.$refs.map.addEventListener('click', this.routeToInfo);
    },
    onBalloonClose() {
      this.$refs.map.removeEventListener('click', this.routeToInfo);
    },
    onChangeType(type) {
      this.selectedCategory = type.id;
    }
  }
};
</script>
<style lang="scss" scoped>
.medicine {
  display: flex;
  justify-content: space-between;
  &__cards {
    width: 41.7%;
  }
  &__map-cont {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    height: 648px;
    width: calc(58.3% - 32px);
    margin-left: 32px;
  }
  &__map {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: #ccc;
    border-radius: 24px;
    position: relative;
    transition: 0.4s all;
  }
  &__map-collapse-btn {
    display: none;
  }
}

.balloon-staff {
  display: grid;
  grid-template-columns: auto 1fr;
}

@media screen and (max-width: 1023px) {
  .medicine {
    max-width: 100%;
    flex-direction: column-reverse;
    &__cards {
      width: 100%;
    }
    &__map-cont {
      position: relative;
      top: unset;
      background-color: #fff;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
    }
    &__map {
      height: 360px;
      margin-bottom: 44px;
    }
    &__map--collapsed {
      height: 112px;
    }
    &__map-collapse-btn {
      height: 40px;
      width: 174px;
      border-radius: 8px;
      padding: 10px 16px;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 87px);
      background-color: var(--rir-arrival);
      cursor: pointer;
      display: block;
      :first-child {
        color: var(--rir-godfather);
        border-bottom: 2px dotted #214eb052;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .medicine {
    &__input-search {
      display: none;
      // position: absolute;
      // z-index: 10;
      // background-color: #fff;
      // box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
      // top: 16px;
      // left: 16px;
    }
  }
}

@media screen and (max-width: 599px) {
  .medicine {
    &__map--collapsed {
      height: 144px;
    }
  }
}
.add {
  margin-bottom: 32px;
  height: 80px;
  margin-top: 12px;
  background: #F9F6FE;
  border-radius: 16px;
  &__text {
    width: 50%;
    margin-left: 24px;
    display: table;
    float: left;
  }
  &__bottom {
    cursor: pointer;
    margin-top: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #9954F2;
  }
  &__title {
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #21043E;
  }
  &__image {
    float: right;
    margin-right: 24px;
    margin-top: -32px;
  }
}

@media screen and (max-width: 599px) {
  .add {
    &__text {
      width: 100%;
    }
    &__image {
      display: none;
    }
  }
}
</style>
