import Vue from 'vue';
import Vuex from 'vuex';
import Api from '@/api/Api';

const api = new Api();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isObjectLoading: null,
    active: null,
    activity: null,
    saveNew: null,
    favorite: null
  },
  mutations: {
    setActive(state, objects) {
      state.active = objects;
    },
    setActivity(state, objects) {
      state.activity = objects;
    },
    setObjectLoading(state, status) {
      state.isObjectLoading = status;
      status && (state.isHasObjects = true);
    },
    setSaveNew(state, types) {
      state.saveNew = types;
    },
    setFavorite(state, types) {
      state.favorite = types;
    }
  },
  getters: {
    getActive: () => state.objects,
    getActivity: () => state.objects,
    getSaveNew: () => state.objects,
    getFavorite: () => state.objects
  },
  actions: {
    async loadActive(ctx, paylod) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getActive(paylod);
      ctx.commit('setActive', res);

      ctx.commit('setObjectLoading', false);
      return res;
    },
    async loadActivity(ctx, paylod) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getActivity(paylod);
      ctx.commit('setActivity', res);
      ctx.commit('setObjectLoading', false);

      return res;
    },
    async loadSaveNew(ctx, payload) {
      console.log('sdfsdfsd', payload);
      const res = await api.setSaveNew(payload);
      ctx.commit('setSaveNew', res);

      return res;
    },
    async loadFavorite(ctx, payload) {
      const res = await api.getFavoriteClick(payload);
      ctx.commit('setFavorite', res);

      return res;
    }
  },
  modules: {}
});
