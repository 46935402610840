<template>
  <section class="rir-drag-upload-file__body">
    <div
      class="rir-drag-upload-file__uploading"
      v-show="isUpload"
    >
      <span>Загрузка...</span>
      <div
        :style="{
          width: `${progress}%`
        }"
        class="rir-drag-upload-file__uploading--progress"
      />
    </div>
    <label
      v-show="!isUpload"
      ref="upload"
      class="rir-drag-upload-file"
      :class="{
        'rir-drag-upload-file--max-files': disabledUpload
      }"
    >
      <div class="rir-drag-upload-file__content">
        <div>
          <r-icon :icon="icon" />
          <span v-if="title">{{ title }}</span>
        </div>
        <span
          v-if="disabledUpload"
          class="rir-drag-upload-file__max-files"
        >Вы ужe загрузили максимум файлов</span>
        <span v-else>{{ getTextCountFiles }}</span>
      </div>
      <input
        :disabled="disabledUpload"
        ref="input"
        :accept="acceptingFiles"
        type="file"
        multiple
        @change="handleFiles($event.target.files)"
      >
    </label>
    <div
      class="rir-drag-upload-file__files"
      v-if="value.length"
    >
      <r-file-list
        :params-headers="paramsHeaders"
        :files="value"
        :count-line="countLine"
        :type-view="listView"
      >
        <template #actions="{ indexItem }">
          <r-icon
            icon="delete"
            class="rir-drag-upload-file__remove"
            @click.native="removeFile(indexItem)"
          />
        </template>
      </r-file-list>
    </div>
  </section>
</template>

<script>

export default {
  name: 'UploadFile',
  components: {},
  props: {
    title: {
      type: String,
      default: 'Выбрать файлы'
    },
    icon: {
      type: String,
      default: 'attach'
    },
    iconFill: {
      type: String,
      default: 'rocky'
    },
    description: {
      type: String,
      default: ''
    },
    value: {
      type: Array
    },
    countFile: {
      type: Number,
      default: 2
    },
    countLine: {
      type: Number,
      default: 2
    },
    listView: {
      type: String,
      default: 'DEFAULT'
    },
    showSize: {
      type: Boolean,
      default: true
    },
    acceptFile: {
      type: Array,
      default: () => ['.jpg', '.jpeg', '.png', '.gif']
    },
    uploadUrl: {
      type: String,
      require: true
    },
    paramsBody: {
      type: Object,
      default: () => ({})
    },
    paramsHeaders: {
      type: Object,
      default: () => ({})
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data: vm => ({
    filesLength: 0,
    filesCountUpload: vm.value.length,
    isUpload: false
  }),
  computed: {
    acceptingFiles() {
      return this.acceptFile.join(',');
    },
    disabledUpload() {
      return this.value.length === this.countFile && this.countFile !== 0;
    },
    getTextCountFiles() {
      if (this.description) return this.description;
      const text = `или перетяните сюда${
        this.countFile ? ` до ${this.countFile} файлов` : ''
      }`;
      return `${text}, объёмом не более 50 МБайт`;
    },
    progress() {
      return ((this.filesCountUpload / this.filesLength) * 100).toFixed(3);
    }
  },
  mounted() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.$refs.upload.addEventListener(
        eventName,
        this.preventDefaults,
        false
      );
    });
    ['dragenter', 'dragover'].forEach(eventName => {
      this.$refs.upload.addEventListener(eventName, this.highlight, false);
    });
    ['dragleave', 'drop', 'dragend'].forEach(eventName => {
      this.$refs.upload.addEventListener(eventName, this.unHighlight, false);
    });
    ['drop'].forEach(eventName => {
      this.$refs.upload.addEventListener(eventName, this.handleDrop, false);
    });
  },
  beforeDestroy() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.$refs.upload.removeEventListener(
        eventName,
        this.preventDefaults,
        false
      );
    });
    ['dragenter', 'dragover'].forEach(eventName => {
      this.$refs.upload.removeEventListener(eventName, this.highlight, false);
    });
    ['dragleave', 'drop', 'dragend'].forEach(eventName => {
      this.$refs.upload.removeEventListener(eventName, this.unHighlight, false);
    });
    ['drop'].forEach(eventName => {
      this.$refs.upload.removeEventListener(eventName, this.handleDrop, false);
    });
  },
  methods: {
    preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    highlight() {
      this.$refs.upload.classList.add('highlight');
    },
    unHighlight() {
      this.$refs.upload.classList.remove('highlight');
    },
    handleDrop(e) {
      const dt = e.dataTransfer;
      const { files } = dt;
      this.handleFiles(files);
    },
    async handleFiles(files) {
      let filesList = files;
      if (this.countFile !== 0) {
        filesList = Array.from(files).slice(
          0,
          this.countFile - this.value.length
        );
      }
      if (filesList.length) this.isUpload = true;
      this.filesLength = filesList.length;
      if (!this.multiple) {
        for (const file of Array.from(filesList)) {
          await this.uploadFile(file);
        }
      } else {
        await this.uploadFile(filesList);
      }
    },
    async uploadFile(file) {
      if (this.multiple) {
        for (const item of Array.from(file)) {
          const re = /(?:\.([^.]+))?$/;
          const fileExt = re.exec(item.name)[1];
          this.filesCountUpload += 1;

          if (!this.acceptFile.includes(`.${fileExt.toLowerCase()}`)) {
            if (this.progress >= 100) {
              this.isUpload = false;
              this.$refs.input.value = '';
            }
            return;
          }
        }
      } else {
        const re = /(?:\.([^.]+))?$/;
        const fileExt = re.exec(file.name)[1];
        this.filesCountUpload += 1;

        if (!this.acceptFile.includes(`.${fileExt.toLowerCase()}`)) {
          if (this.progress >= 100) {
            this.isUpload = false;
            this.$refs.input.value = '';
          }
          return;
        }
      }

      return new Promise(resolve => {
        const url = this.uploadUrl;
        const formData = new FormData();
        // const base64 = [];
        if (this.multiple) {
          for (const item of Array.from(file)) {
            formData.append('file', item);

            // Convert to base64
            // const reader = new FileReader();
            // reader.onloadend = function() {
            //   const b64 = reader.result.replace(/^data:.+;base64,/, '');
            //   console.log('b64', b64);
            //   base64.push({ name: file.name || 'noname', content: b64 });
            // };
            // reader.readAsDataURL(item);
          }
        } else {
          formData.append('file', file);
          formData.append('filename', file.name);
        }
        // formData.append('userId', undefined);
        const params = Object.keys(this.paramsBody);
        if (params.length !== 0) {
          params.forEach(key => {
            formData.append(key, this.paramsBody[key]);
          });
        }
        fetch(url, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            ...this.paramsHeaders
          },
          body: formData
          // body: base64   // for base64 format
        })
          .then(async response => {
            if (response.status !== 200) {
              throw Error(`error load image - ${response.status}`);
            }

            const reader = response.body.getReader();
            // const contentLength = +response.headers.get('Content-Length')
            let receivedLength = 0;
            const chunks = [];
            while (true) {
              const { done, value } = await reader.read();

              if (done) {
                break;
              }

              chunks.push(value);
              receivedLength += value.length;
            }
            const chunksAll = new Uint8Array(receivedLength);
            let position = 0;
            for (const chunk of chunks) {
              chunksAll.set(chunk, position);
              position += chunk.length;
            }
            const result = new TextDecoder('utf-8').decode(chunksAll);
            const files = JSON.parse(result);
            const filesValue = [...this.value, files];
            this.$emit('input', filesValue);

            resolve();
          })
          .catch(error => {
            console.log('error', error);
          })
          .finally(() => {
            if (this.progress >= 100) {
              this.isUpload = false;
              this.$refs.input.value = '';
            }
            resolve();
          });
      });
    },
    removeFile(index) {
      this.value.splice(index, 1);
      this.filesCountUpload -= 1;
      this.$emit('input', this.value);
    }
  }
};
</script>
<style lang="scss" scoped>
.rir-drag-upload-file__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rir-drag-upload-file__uploading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  border-radius: 8px;
  overflow: hidden;
}
.rir-drag-upload-file, .rir-file-list__actions {
  display: flex;
  align-items: center;
}
.rir-drag-upload-file {
  width: 100%;
  height: 96px;
  border: 1px dashed #04153e;
  padding: 0 16px;
  border-radius: 8px;
  cursor: pointer;
}
.rir-drag-upload-file__content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.rir-drag-upload-file>input {
  display: none;
}
.rir-drag-upload-file__content>div {
  color: #04153e;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.rir-drag-upload-file__content>div>span {
  margin-left: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.rir-drag-upload-file__content>span {
  text-align: center;
  color: rgba(4,21,62,.72);
  font-size: 13px;
  line-height: 16px;
}
.files-delete {
  cursor: pointer;
  margin-left: 13px;
}

.files {
  margin-top: 24px;
  color: #04153E;
  margin-bottom: 10px;
  height: 48px;
  border-radius: 8px;
  background: rgba(#3D75E4, 0.08);
  display: flex;
  align-items: center;
}

.files_icon {
  height: 100%;
  width: 76px;
  border-radius: 8px;
  background: #E4EDFB;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.files-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.files-body {
  height: 100%;
  width: calc(100% - 76px);
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
<style>
.rir-drag-upload-file__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rir-drag-upload-file__uploading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  border-radius: 8px;
  overflow: hidden;
}
.rir-drag-upload-file__uploading>span {
  color: rgba(4,21,62,.48);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.rir-drag-upload-file__uploading--progress {
  transition: width .5s;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(61,117,228,.16);
}
</style>
