<template>
  <div class="card">
    <div class="card__img-cont">
      <img
        class="card__img"
        loading="lazy"
        v-if="img"
        :src="img"
        :alt="name"
      >
      <r-icon
        v-else-if="selectedCategory === 3"
        icon="trade-module"
        size="32"
        fill="alien"
      />
      <r-icon
        v-else
        icon="trade-module"
        size="32"
        fill="alien"
      />
    </div>
    <div class="card__info">
      <h3 class="card__title">
        {{ name }}
      </h3>
      <p class="card__address">
        {{ address }}
      </p>
      <div
        v-if="$slots.default"
        class="card__additional"
      >
        <!-- <rir-icon v-if="icon" :icon="icon" /> -->
        <!-- <div class="card__additional-info"> -->
        <slot />

        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['name', 'address', 'img', 'icon', 'selectedCategory']
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  margin-bottom: 32px;
  &__img-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 56px;
    min-height: 56px;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    margin-right: 16px;
    background-color: var(--rir-arrival);
    overflow: hidden;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: left;
    overflow: hidden;
  }
  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--rir-titanic);
  }
  &__address {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: var(--rir-titanic);
    opacity: 0.72;
  }
  &__additional {
    margin-top: 12px;
    display: flex;
    align-items: center;
  }
  // &__additional-info > * {
  //   margin-left: 6px;
  //   font-weight: 500;
  //   font-size: 13px;
  //   line-height: 16px;
  //   color: var(--rir-rocky);
  // }
  &:hover {
    .card__title {
      color: var(--rir-godfather);
    }
    .card__address {
      color: var(--rir-godfather);
      opacity: 0.72;
    }
  }
}
</style>
