<template>
  <div class="contact flex align-center">
    <r-icon
      :icon="icon"
      fill="rocky"
    />
    <a
      class="contact__link sulguni"
      v-for="(contact, index) in contacts"
      :key="index"
      :href="contact.href"
      :target="contact.href.includes('http') ? '_blank' : '_self'"
    >
      {{ contact.title }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  props: ['contacts', 'icon']
};
</script>

<style lang="scss" scoped>
.contact {
  &__link {
    margin-left: 16px;
    &:first-of-type {
      margin-left: 8px;
    }
  }
}
</style>
