<template>
  <div>
    <div style="width: 100%; margin-bottom: 26px;">
      <router-link
        :to="'/'"
        class="inline-flex align-center rocky--text"
        event
        @click.native.prevent="routeBack"
      >
        <r-icon
          icon="arrow-left"
          class="mr-2"
        />
        <div style="margin-top: -2px">
          Назад
        </div>
      </router-link>
    </div>
    <loader v-if="isLoading" />
    <div
      v-else
      class="info flex justify-between"
      :key="countReset"
    >
      <div class="info__description">
        <div class="flex align-center mb-2">
          <h1 class="taleggio">
            {{ itemsDef.title }}
          </h1>
          <div @click="favoriteFnc()">
            <r-icon
              class="ml-3 pointer"
              icon="favorite-add"
              size="20"
              fill="rocky"
              v-if="userid && !favorite"
            />
            <svg
              v-if="userid && favorite"
              style="margin-left: 15px; margin-top: -4px;"
              class="pointer"
              width="14"
              height="20"
              viewBox="0 0 14 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 3.01535C0 1.35002 1.34315 0 3
              0H11C12.6569 0 14 1.35002 14 3.01535V17.986C14 19.6802 12.0426 20.6146 10.7362 19.544L7
              16.4822L3.26382 19.544C1.95737 20.6146 0 19.6802 0 17.986V3.01535Z"
                fill="#3D75E4"
              />
            </svg>
          </div>
        </div>
        <p class="info__address feta mt-1 mb-3">
          {{ itemsDef.address }}
        </p>
        <a
          :href="'/create-route?toCoords='+ coordinates.join(',')"
          class="flex align-items-center"
        >
          <div
            style="height: 15px;"
            class="mb-8 flex align-items-center"
          >
            <r-icon
              style="float: left;"
              icon="geopoint"
              size="16"
              fill="rocky"
            />
            <p class="ml-2 point">Построить маршрут</p>
          </div>
        </a>
        <div
          class="info__mob-map-cont"
          ref="mobMapCont"
        />
        <r-slideshow
          v-if="galleryImages.length"
          :is-preview-block="false"
          :is-step-badge="true"
          :is-fill-image="false"
          class="mt-8 mb-8"
          :files="galleryImages"
          @click="openModalGallery"
        />
        <!--
                 <div :class="['flex', 'flex-col']"
                      class="open_image mb-8 rounded-16"
                      @click="openModalImage($event,{src: '/cityProperty/rest/' + img, name: itemsDef.name})">
                   <img
                     class="info__img rounded-16"
                     :src="'/cityProperty/rest/' + img"
                     :alt="itemsDef.name"
                   />
                   <rir-icon icon="search" size="56" fill="amelie"></rir-icon>
                 </div>
     -->

        <p class="feta opacity-48">
          Тип объекта
        </p>
        <p
          class="parmigiano mt-2"
          v-if="itemsDef.type == 0"
        >
          -
        </p>
        <p
          class="parmigiano mt-2"
          v-if="itemsDef.type == 1"
        >
          Муниципальный
        </p>
        <p
          class="parmigiano mt-2"
          v-if="itemsDef.type == 2"
        >
          Коммерческий
        </p>

        <p class="feta opacity-48 mt-8">
          Собственник
        </p>
        <p class="parmigiano mt-2">
          {{ itemsDef.owner }}
        </p>
        <div
          style="display: table; width: 150px; float: left;"
          class=" mb-8"
        >
          <p class="feta opacity-48 mt-8">
            Площадь
          </p>
          <p class="parmigiano mt-2">
            {{ itemsDef.areaS }}
          </p>
        </div>
        <div
          style="display: inline-block; width: 100px; margin-left: 32px;"
          class="mb-8"
        >
          <p class="feta opacity-48 mt-8">
            Этаж
          </p>
          <p class="parmigiano mt-2">
            {{ itemsDef.floorS }}
          </p>
        </div>
        <div style="display: table;width: 100%" />
        <div v-if="itemsDef.description">
          <text-accordion
            class=" mb-8"
            :key="countText"
          >
            <template #default>
              <p
                class="parmigiano"
                v-html="itemsDef.description"
              />
            </template>
          </text-accordion>
        </div>
        <div
          v-if="itemsDef.contacts.length > 0"
          :class="['info__contacts', 'text-rocky']"
        >
          <contact-list :contacts="itemsDef.contacts" />
        </div>

        <div
          class="info__transport"
          v-if="transportStopList.length > 0"
        >
          <span class="info__transport-title feta"> Ближайшие остановки </span>
          <stops-list :list="transportStopList" />
        </div>
        <div class="mt-8">
          <h2 class="taleggio mb-3">
            Поделиться
          </h2>
          <share />
        </div>
      </div>

      <div
        class="info__pc-map-cont"
        ref="pcMapCont"
      >
        <div
          :class="isMap ? 'info__map info__map--collapsed': 'info__map' "
          ref="map"
        >
          <rir-map
            v-if="isShowMap && currentObject"
            :center="coordinates"
          >
            <ymap-marker
              :coords="currentObject"
              :marker-id="'clinic_' + 1"
              :icon="$markerIcon()"
            />
          </rir-map>
          <button
            class="info__map-collapse-btn feta"
            @click="isMap = !isMap"
          >
            <span> {{ isMap ? 'Развернуть' : 'Свернуть' }} карту </span>
          </button>
        </div>
      </div>
    </div>

    <r-modal
      ref="modal"
      close-icon
    />
    <r-modal
      ref="modalImage"
      :fullscreen="true"
      close-icon
    />
  </div>
</template>

<script>
import ModalGallery from '@/components/ModalGallery';
import TextAccordion from '../components/TextAccordion.vue';
import StopsList from '../components/StopsList.vue';
import ContactList from '../components/ContactList.vue';
import RirMap from '../components/RirMap.vue';
import Share from '../components/Share.vue';
import Api from '@/api/Api';
import ModalImage from '../components/ModalImage';
import RirGallery from '@/components/RirGallery';
import Loader from '@/components/Loader.vue';

export default {
  name: 'RentInfo',
  components: {
    Loader,
    TextAccordion,
    StopsList,
    ContactList,
    RirMap,
    Share,
    RirGallery
  },
  data() {
    return {
      isShowMap: false,
      isMapCollapsed: false,
      isMobile: false,
      transportStopList: [],
      isFromMainPage: false,
      prevRoute: {},
      isMap: true,
      itemsDef: {
        address: null,
        title: null,
        owner: null,
        areaS: null,
        floorS: null,
        description: null,
        phone: null,
        email: null,
        img: null,
        name: null,
        contacts: [],
        images: []
      },
      countText: 0,
      countReset: 0,
      coordinates: [47.519624, 42.206329],
      email: true,
      phone: true,
      countEmail: 0,
      countPhone: 0,
      objectsEmail: [],
      objectsPhone: [],
      userid: false,
      favorite: false
    };
  },

  computed: {
    galleryImages() {
      if (!this.itemsDef?.images) {
        return [];
      }

      return this.itemsDef?.images?.map(img => ({
        url: img,
        filename: 'Без имени',
        type: 'image/jpeg'
      }));
    },
    isLoading() {
      return this.$store.state.isObjectLoading;
    },
    currentObject() {
      this.objects();
      return this.coordinates;
    },
    contacts() {
      this.countReset++;
      return contacts;
    }
  },
  async created() {
    this.objects();
  },
  async mounted() {
    const api = new Api();
    const res = await api.getUserInfo();
    if (res?.userId > 0) {
      let favorite = await api.getFavorite();
      favorite = favorite.fav.filter(el => ((el.id == this.$route.params.id)));
      if (favorite.length > 0) {
        this.userid = true;
        this.favorite = true;
      } else {
        this.userid = true;
      }
    }
    // this.countReset++;
  },
  activated() {
    this.isShowMap = true;
    window.addEventListener('resize', this.onWindowResize);
  },
  deactivated() {
    this.isShowMap = false;
    window.removeEventListener('resize', this.onWindowResize);
  },
  updated() {
    this.onWindowResize();
  },
  methods: {
    favoriteFnc() {
      this.$store.dispatch('loadFavorite', this.$route.params.id)
        .then(result => {
          if (result.isSet == false) {
            this.favorite = false;
          }
          if (result.isSet == true) {
            this.favorite = true;
          }
        });
    },
    async openModalImage(e, image) {
      e.preventDefault();
      await this.$refs.modalImage.openModal(ModalImage, {
        img: image.src,
        name: image.name
      });
    },
    activated() {
      this.isShowMap = true;
    },
    objects() {
      const v = this;
      v.isShowMap = false;
      this.objectsEmail = [];
      this.objectsPhone = [];
      this.$store.dispatch('loadActivity', {
        withHidden: 1,
        id: this.$route.params.id,
        currentMainMenuNumber: 38
      }).then(result => {
        console.log(result);
        if ((result.all[0].hidden == 0) && (result.all[0].inApproval == 0)) {
          v.itemsDef.id = result.all[0].id ? result.all[0].id : '';
          v.itemsDef.title = result.all[0].title ? result.all[0].title : '';
          v.itemsDef.address = result.all[0].address ? result.all[0].address : '';
          v.coordinates[0] = result.all[0].lat ? result.all[0].lat : 47.5259125;
          v.coordinates[1] = result.all[0].lng ? result.all[0].lng : 42.1899268;
          v.itemsDef.owner = result.all[0].firm;
          v.itemsDef.floorS = result.all[0].floorS;
          v.itemsDef.description = result.all[0].description;
          v.itemsDef.areaS = result.all[0].areaS;
          v.itemsDef.type = ((result.all[0].type != 1) && (result.all[0].type != 2)) ? 0 : result.all[0].type;
          v.itemsDef.inApproval = result.all[0].inApproval;
          v.itemsDef.images = result.all[0].images;
          const api = new Api();
          v.transportStopList = api.setTransport({ lat: v.coordinates[0], lng: v.coordinates[1] });
          if (result.all[0].email && JSON.parse(result.all[0].email).length > 0) {
            this.objectsEmail = JSON.parse(result.all[0].email);
          }
          if (result.all[0].phone && JSON.parse(result.all[0].phone).length > 0) {
            this.objectsPhone = JSON.parse(result.all[0].phone);
          }
          const contacts = [];
          const phones = this.objectsPhone;
          const emails = this.objectsEmail;
          const { web } = this.currentObject;
          if (phones?.length) {
            contacts.push({
              icon: 'telephone',
              contacts: phones.map(phone => ({
                title: phone,
                href: `tel:${phone.replace(/[^0-9+.]/g, '')}`
              }))
            });
          }

          if (emails?.length) {
            contacts.push({
              icon: 'email',
              contacts: emails.map(email => ({
                title: email,
                href: `mailto:${email}`
              }))
            });
          }
          v.itemsDef.contacts = contacts;
          v.img = result.all[0].photo1 ? result.all[0].photo1 : null;
          if (v.img) {
            v.itemsDef.img = v.img;
          } else {
            v.itemsDef.img = null;
          }
        }
        v.isShowMap = true;
        this.countText++;
      }, error => {
        console.error(error);
      });

      return v.itemsDef;
    },
    routeBack() {
      this.$router.go(-1);
    },
    async openModalGallery(index) {
      await this.$refs.modal.openModal(ModalGallery, {
        items: [index]
      });
    },
    onWindowResize() {
      if (this.isLoading || !this.$refs.pcMapCont) return;
      const width = window.innerWidth;
      const { map } = this.$refs;
      if (width > 1023) {
        !this.$refs.pcMapCont?.hasChildNodes() && this.$refs.pcMapCont.appendChild(map);
        this.isMapCollapsed = false;
        this.isMobile = false;
      } else {
        this.isMapCollapsed = true;
        !this.$refs.mobMapCont.hasChildNodes() && this.$refs.mobMapCont.appendChild(map);
        this.isMobile = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .rir-modal__content-rir-icon_close {
  z-index: 1002;
}

.open_image {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  //width: 100%;

  &:before {
    content: '';
    background: linear-gradient(360deg, rgba(4, 21, 62, 0.8) 0%, rgba(4, 21, 62, 0) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    opacity: 0;
  }

  img {
    z-index: -1;
  }

  .rir-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease-in-out;
  }

  &:hover {
    &:before {
      transform: translateY(0);
      opacity: 1;
    }

    .rir-icon {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
}

.med {
  font-family: Golos UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153e;
  opacity: 0.48;
}

.info {
  &__name {
    text-decoration: underline;
  }

  &__description {
    text-align: left;
    width: 41.7%;
  }

  &__gallery {
    height: 234px;
  }

  &__work-hours {
    color: var(--rir-titanic);
  }

  &__transport-title {
    opacity: 0.48;
  }

  &__pc-map-cont {
    padding-left: 32px;
    width: 58.3%;
  }

  &__img {
    aspect-ratio: 16/10;
    object-fit: cover;
    // height: 234px;
    // width: 375px;
    width: 100%;
  }

  &__mob-map-cont {
    display: none;
  }

  &__map {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    max-width: 100%;
    width: 100%;
    height: 648px;
    background-color: #ccc;
    border-radius: 24px;
    transition: 0.4s all;
  }

  &__show-on-map {
    display: flex;
    align-items: center;
    background: none;
    margin-top: 18px;
    color: var(--rir-rocky);

    &:hover {
      cursor: pointer;
    }
  }

  &__map-collapse-btn {
    height: 40px;
    width: 174px;
    border-radius: 8px;
    padding: 10px 16px;
    position: absolute;
    bottom: -20px;
    left: calc(50% - 87px);
    background-color: var(--rir-arrival);
    cursor: pointer;
    display: none;

    :first-child {
      color: var(--rir-godfather);
      border-bottom: 2px dotted #214eb052;
    }
  }

  &__institution-link {
    &:hover {
      * {
        color: var(--rir-godfather);
      }
    }
  }
}

.ext-link {
  border-bottom: 2px solid var(--rir-alien);
}

::v-deep .rir-modal__content {
  display: grid;
  align-items: center;
}

@media screen and (max-width: 1023px) {
  .info {
    &__description {
      width: 100%;
    }

    &__map {
      position: relative;
      top: unset;
      margin-bottom: 44px;
      height: 447.31px;
    }

    &__map--collapsed {
      height: 112px;
    }

    &__mob-map-cont {
      display: block;
    }

    &__pc-map-cont {
      display: none;
    }

    &__map-collapse-btn {
      display: block;
    }
  }
}

@media screen and (max-width: 599px) {
  .info {
    &__map--collapsed {
      height: 144px;
    }
  }
}

.point {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3D75E4;
}

.pointer {
  cursor: pointer;
}
</style>
<style>
.RImage {
  z-index: 0 !important;
}
</style>
